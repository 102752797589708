import React from "react";
import SelectableOption from "./SelectableOption";
import Grid from "@material-ui/core/Grid";
import * as Consts from "../Consts";
import "./StatsChoiceScreen.css";

class StatsChoiceScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStats: [], // Tracks selected stats in order
        };
        this.handleStatSelection = this.handleStatSelection.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleStatSelection(index) {
        const { selectedStats } = this.state;

        if (selectedStats.length < 6) {
            this.setState({ selectedStats: [...selectedStats, index] });
        }
    }

    handleReset() {
        this.setState({ selectedStats: [] });
    }

    handleSubmit() {
        const { selectedStats } = this.state;

        console.log("Socket ready state: ", this.props.socket.readyState); // Log socket state
        if (this.props.socket.readyState !== 1) {
            console.error("Socket is not ready. Message not sent.");
            return;
        }

        // Build the payload message
        const payloadMessage = {
            PAYLOAD_MESSAGE_TYPE: Consts.STATS_PAYLOAD_RESPONSE_MESSAGE,
            UID: this.props.userID,
            SelectedPlayerStats: selectedStats.map((index) => this.props.options[index]),
        };

        const result = JSON.stringify({
            messageType: Consts.PAYLOAD_RESPONSE_MESSAGE,
            PAYLOAD_MESSAGE: payloadMessage,
            roomCode: this.props.roomCode,
            userUID: this.props.userID,
        });

        console.log("Sending stats selection:", result); // Log the payload
        this.props.socket.send(result);
        this.props.callback();
    }

    render() {
        const { options, title } = this.props;
        const { selectedStats } = this.state;

        return (
            <div className="stats-choice-screen">
                <h2>{title}</h2>
                <Grid container spacing={2} justifyContent="center">
                    {/* Left Side: Selectable Options */}
                    <Grid item xs={12} md={6}>
                        <h3>Available Stats</h3>
                        <Grid container direction="column" alignItems="center" spacing={1}>
                            {options.map((item, index) => {
                                const isSelected = selectedStats.includes(index);

                                return (
                                    <Grid item key={index}>
                                        <SelectableOption
                                            text={item}
                                            onClick={() => this.handleStatSelection(index)}
                                            disabled={isSelected || selectedStats.length >= 6}
                                            isSelected={isSelected}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>

                    {/* Right Side: Selected Stats Boxes */}
                    <Grid item xs={12} md={6}>
                        <h3>Your Selections</h3>
                        <Grid container direction="column" alignItems="center" spacing={1}>
                            {Array.from({ length: 6 }).map((_, i) => {
                                const selectedIndex = selectedStats[i];
                                const text = selectedIndex != null ? options[selectedIndex] : "";
                                const rank = i + 1; // Rank goes from 1 to 6

                                return (
                                    <Grid item key={i} className="ranked-selection-container">
                                        <div className={`selection-box ${text ? "filled" : ""}`}>
                                            {text || "Empty"}
                                        </div>
                                        <div className="rank-circle">{rank}</div>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>

                {/* Buttons */}
                <div className="buttons">
                    <button onClick={this.handleReset} disabled={selectedStats.length === 0}>
                        Reset
                    </button>
                    <button onClick={this.handleSubmit} disabled={selectedStats.length < 6}>
                        Submit
                    </button>
                </div>
            </div>
        );
    }
}

export default StatsChoiceScreen;


/*
import React from "react";
import SelectableOption from "./SelectableOption";
import Grid from "@material-ui/core/Grid";
import * as Consts from "../Consts";
import "./StatsChoiceScreen.css";

class StatsChoiceScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStats: [], // Tracks selected stats in order
        };
        this.handleStatSelection = this.handleStatSelection.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleStatSelection(index) {
        const { selectedStats } = this.state;

        if (selectedStats.length < 6) {
            this.setState({ selectedStats: [...selectedStats, index] });
        }
    }

    handleReset() {
        this.setState({ selectedStats: [] });
    }

    handleSubmit() {
        const { selectedStats } = this.state;

        console.log("Socket ready state: ", this.props.socket.readyState); // Log socket state
        if (this.props.socket.readyState !== 1) {
            console.error("Socket is not ready. Message not sent.");
            return;
        }

        // Build the payload message
        const payloadMessage = {
            PAYLOAD_MESSAGE_TYPE: Consts.STATS_PAYLOAD_RESPONSE_MESSAGE,
            UID: this.props.userID,
            SelectedPlayerStats: selectedStats.map(index => this.props.options[index])
        };

        const result = JSON.stringify({
            messageType: Consts.PAYLOAD_RESPONSE_MESSAGE,
            PAYLOAD_MESSAGE: payloadMessage,
            roomCode: this.props.roomCode,
            userUID:this.props.userID,
        });


        console.log("Sending stats selection:", result); // Log the payload
        this.props.socket.send(result);
        this.props.callback();
    }



    render() {
        const { options, title } = this.props;
        const { selectedStats } = this.state;

        return (
            <div className="stats-choice-screen">
                <h2>{title}</h2>
                <Grid container spacing={3}>
                    {/!* Left Side: Selectable Options *!/}
                    <Grid item xs={6}>
                        <h3>Available Stats</h3>
                        <Grid container direction="column" alignItems="center" spacing={2}>
                            {options.map((item, index) => {
                                const isSelected = selectedStats.includes(index);

                                return (
                                    <Grid item key={index}>
                                        <SelectableOption
                                            text={item}
                                            onClick={() => this.handleStatSelection(index)}
                                            disabled={isSelected || selectedStats.length >= 6}
                                            isSelected={isSelected}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>

                    {/!* Right Side: Selected Stats Boxes *!/}
                    <Grid item xs={6}>
                        <h3>Your Selections</h3>
                        <Grid container direction="column" alignItems="center" spacing={2}>
                            {Array.from({ length: 6 }).map((_, i) => {
                                const selectedIndex = selectedStats[i];
                                const text = selectedIndex != null ? options[selectedIndex] : "";
                                const rank = i + 1; // Rank goes from 1 to 6

                                return (
                                    <Grid item key={i}>
                                        <div className={`selection-box ${text ? "filled" : ""}`}>
                                            {text || "Empty"}
                                            <span className="rank-label">{`Rank: ${rank}`}</span>
                                        </div>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>

                {/!* Buttons *!/}
                <div className="buttons">
                    <button onClick={this.handleReset} disabled={selectedStats.length === 0}>
                        Reset
                    </button>
                    <button
                        onClick={this.handleSubmit}
                        disabled={selectedStats.length < 6}
                    >
                        Submit
                    </button>
                </div>
            </div>
        );
    }
}

export default StatsChoiceScreen;
*/
