export const CURRENT_WINDOW = "currentWindow"
export const WAITING_SCREEN = "waitingScreen"
export const LOGIN_SCREEN = "loginScreen"
export const LOGIN = "LOGIN";
export const MESSAGE_TYPE = "messageType"
export const LOGIN_RESPONSE = "LOGIN_RESPONSE"
export const PLAYER_CHOICE_SCREEN = "SelectOption"
export const SHOW_PLAYER_CHOICE = "SHOW_PLAYER_SELECTION"
export const PLAYER_SELECTION_MADE = "PLAYER_SELECTION_MADE"
export const UPDATE_UID_SESSION = "UPDATE_UID_SESSION"
export const  LAST_SENT_MESSAGE = "LAST_SENT"
export const LAST_SENT = "lastSent"

// Room full errors
export const Max_Players_Reached = "Max_Players_Reached"
export const ROOM_FULL = "ROOM_FULL";

// Reconnection
export const RECONNECT_PLAYER = "RECONNECT_PLAYER";
export const RECONNECTED_PLAYER = "RECONNECTED_PLAYER";
export const PLAYER_STATE = "PLAYER_STATE";
export const RECEIVE_PLAYER_STATE_FROM_SERVER = "RECEIVE_PLAYER_STATE_FROM_SERVER";
export const CONFIRM_PLAYER_STATE_RECEIVED_BY_CLIENT = "CONFIRM_PLAYER_STATE_RECEIVED_BY_CLIENT";

export const ROOM_DETAILS = "ROOM_DETAILS";
export const NO_ROOM_FOUND = "NO_ROOM_FOUND";

// Move players to screens
export const MOVE_TO_SCREEN = "MOVE_TO_SCREEN";
export const KNOCKED_OUT_SCREEN = "KNOCKED_OUT_SCREEN";
export const GAME_CLOSED = "GAME_CLOSED";

// Double Answers Round
export const DOUBLE_ANSWERS_ROUND = "DOUBLE_ANSWERS_ROUND";
export const DOUBLE_ANSWERS_SCREEN = "DoubleAnswersScreen";
export const ON_DOUBLE_ANSWERS_RECEIVED = "ON_DOUBLE_ANSWERS_RECEIVED";
export const SELECTED_DOUBLE_ANSWERS = "SELECTED_DOUBLE_ANSWERS";

// Display Correct Answer
export const DISPLAY_CORRECT_ANSWER = "DISPLAY_CORRECT_ANSWER";
export const DISPLAY_CORRECT_ANSWERS = "DISPLAY_CORRECT_ANSWERS";
export const CORRECT_ANSWER_SCREEN = "CORRECT_ANSWER_SCREEN";

// Sleeves Notification
export const DISPLAY_CORRECT_ANSWERS_WITH_SLEEVES = "DISPLAY_CORRECT_ANSWERS_WITH_SLEEVES";
export const SLEEVES_IS_BONUS = "SLEEVES_IS_BONUS";

// Timed Out / Frozen
export const TIMEOUT_SCREEN = "TimeoutScreen";

// Payload message
export const SEND_MESSAGE_TO_PLAYERS = "SEND_MESSAGE_TO_PLAYERS";
export const PAYLOAD_MESSAGE = "PAYLOAD_MESSAGE";
export const PAYLOAD_RESPONSE_MESSAGE = "PAYLOAD_RESPONSE_MESSAGE";
export const PAYLOAD_MESSAGE_TYPE = "PAYLOAD_MESSAGE_TYPE";

// Player stats
export const STATS_PAYLOAD_MESSAGE = "STATS_PAYLOAD_MESSAGE";
export const STATS_CHOICE_SCREEN = "STATS_CHOICE_SCREEN";
export const STATS_PAYLOAD_RESPONSE_MESSAGE = "STATS_PAYLOAD_RESPONSE_MESSAGE";