import './App.css';
import ReconnectingWebSocket from 'reconnecting-websocket';
import LoginScreen from './components/LoginScreen'
import React from 'react'
import * as Consts from './Consts'
import LoadingScreen from "./components/LoadingScreen";
import SelectOptionScreen from "./components/SelectOptionScreen";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import WakeLock from 'react-wakelock-react16';
import CurrentStateDebug from './components/CurrentStateDebug'
import RoomFull from './components/RoomFull';
import ReconnectedPlayer from "./components/ReconnectedPlayer";
import KnockedOut from "./components/KnockedOut";
import * as uuid from "uuid";
import DoubleAnswersScreen from "./components/DoubleAnswersScreen";
import CorrectAnswerScreen from "./components/CorrectAnswerScreen";
import TimedOut from "./components/TimedOut";
import StatsChoiceScreen from "./components/StatsChoiceScreen";
import {SEND_MESSAGE_TO_PLAYERS} from "./Consts";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.rws = new ReconnectingWebSocket('ws://api.quiztalgames.com:2020/quest');
        // this.rws = new ReconnectingWebSocket('ws://localhost:2020/quest');
        this.hash = require('object-hash');

        this.state = {
            "currentWindow": Consts.LOGIN_SCREEN,
            "choiceOptions": ["One", "Two", "Three"],
            "choiceTitle": "This is a test question",
            "lastMessageHash": null,
            "roomFull": false
        }

        // fetch or generate a UID based on user's device
        this.CheckForUID();

        this.Reconnect();
        // this.UpdateUidOnConnect();

        this.handleMessageFromServer = this.handleMessageFromServer.bind(this);
        this.rws.addEventListener('message', (event) => {
            console.log('Message from server ', event.data);
            let response = JSON.parse(event.data)

            this.handleMessageFromServer(response)
            this.setState({"lastMessageHash": this.hash(response)})
        });

        this.moveToWaitingCallback = this.moveToWaitingCallback.bind(this)
    }

    Reconnect()
    {
        this.rws.addEventListener('open', () => {
            let uid = localStorage.getItem('uid');
            if (uid) {
                this.rws.send(JSON.stringify({
                    messageType: Consts.RECONNECT_PLAYER,
                    userUID: uid,
                    roomCode: this.state.roomCode
                }));
            }
        });
    }

    UpdateUidOnConnect()
    {
        this.rws.addEventListener('open', () => {
            let uid = localStorage.getItem('uid');
            if (this.state["currentWindow"] !== Consts.LOGIN_SCREEN && uid) {
                this.rws.send(JSON.stringify({
                    messageType: Consts.UPDATE_UID_SESSION,
                    userUID: uid,
                    roomCode: this.state.roomCode
                }));
            }
        });
    }

    CheckForUID()
    {
        let uid = localStorage.getItem('uid');
        if (!uid)
        {
            uid = this.GenerateUID();
            localStorage.setItem('uid', uid);
        }
    }

    GenerateUID()
    {
        return uuid.v4();
    }

    handleMessageFromServer(response) {
        let uid = localStorage.getItem('uid');

        switch (response[Consts.MESSAGE_TYPE])
        {
            case Consts.LOGIN_RESPONSE:
                if (!response["success"]) {
                    console.log("No available session for user");
                    this.setState({"currentWindow": Consts.LOGIN_SCREEN})
                } else {
                    console.log("Logged user in");
                    this.setState({"userID": response["userUID"], "roomCode": response["roomCode"]})
                }
                break;
            case Consts.SHOW_PLAYER_CHOICE:
                this.setState({
                    "currentWindow": Consts.PLAYER_CHOICE_SCREEN,
                    "choiceTitle": response["title"],
                    "choiceOptions": response["choices"]
                });
                break;
            case Consts.LAST_SENT_MESSAGE:
                if (response[Consts.LAST_SENT] != null && this.hash(response[Consts.LAST_SENT]) !== this.state.lastMessageHash) {
                    this.handleMessageFromServer(response[Consts.LAST_SENT])
                }
                break;
            case Consts.Max_Players_Reached:
                this.setState({
                    "currentWindow" : Consts.ROOM_FULL,
                    "roomFull": true }
                );
                console.error('Max players reached in room');
                break;
            case Consts.ROOM_DETAILS:
                this.setState({"roomCode": response.roomCode});
                break;
            case Consts.NO_ROOM_FOUND:
                break;

            case Consts.RECEIVE_PLAYER_STATE_FROM_SERVER:
                let playerStateObj = JSON.parse(response[Consts.PLAYER_STATE]);
                let roomCode = playerStateObj["RoomCode"];
                this.setState({
                    "currentWindow" : Consts.RECONNECTED_PLAYER,
                    "roomCode" : roomCode,
                    "userID" : uid
                });
                this.ConfirmPlayerStateReceived();
                break;

            case Consts.MOVE_TO_SCREEN:
                let screenToMoveTo = response["screen"];
                switch (screenToMoveTo)
                {
                    case Consts.KNOCKED_OUT_SCREEN:
                        this.setState({"currentWindow": Consts.KNOCKED_OUT_SCREEN})
                        break;

                    case Consts.WAITING_SCREEN:
                        this.setState({"currentWindow" : Consts.WAITING_SCREEN})
                        break;

                    case Consts.TIMEOUT_SCREEN:
                        this.setState({"currentWindow" : Consts.TIMEOUT_SCREEN});
                        break;

                    default:
                        console.error('Unknown screen requested');
                        break;
                }
                break;
            case Consts.DOUBLE_ANSWERS_ROUND:
                this.setState({
                    "currentWindow": Consts.DOUBLE_ANSWERS_SCREEN,
                    "choiceTitle": response["title"],
                    "choiceOptions": response["choices"]
                });
                break;
            case Consts.DISPLAY_CORRECT_ANSWER:
                this.setState({
                    currentWindow: Consts.CORRECT_ANSWER_SCREEN,
                    choiceTitle: response.title,
                    choiceOptions: response.choices,
                    selectedOptions: response["PLAYER_ANSWERS"], // directly use the PLAYER_ANSWERS as selected option index
                    correctIndex: parseInt(response["CORRECT_INDEX"]),
                    isBonus : false,
                });
                break;

            case Consts.DISPLAY_CORRECT_ANSWERS:
                this.setState({
                        currentWindow: Consts.CORRECT_ANSWER_SCREEN,
                        choiceTitle: response.title,
                        choiceOptions: response.choices,
                        selectedOptions: response["PLAYER_ANSWERS"], // Assuming playerAnswers is an object mapping UIDs to arrays of answers
                        correctIndex: parseInt(response["CORRECT_INDEX"]),
                        isBonus : false,
                });
                break;

            case Consts.DISPLAY_CORRECT_ANSWERS_WITH_SLEEVES:
                this.setState({
                    currentWindow: Consts.CORRECT_ANSWER_SCREEN,
                    choiceTitle: response.title,
                    choiceOptions: response.choices,
                    selectedOptions: response["PLAYER_ANSWERS"], // Assuming playerAnswers is an object mapping UIDs to arrays of answers
                    correctIndex: parseInt(response["CORRECT_INDEX"]),
                    isBonus : true,
                });
                break;

            case Consts.SEND_MESSAGE_TO_PLAYERS:
                let payloadMessageRaw = response[Consts.PAYLOAD_MESSAGE];
                let payloadMessage = JSON.parse(payloadMessageRaw); // Parse the inner JSON string into an object

                switch (payloadMessage[Consts.PAYLOAD_MESSAGE_TYPE])
                {
                    case Consts.STATS_PAYLOAD_MESSAGE:
                        this.setState({
                            "currentWindow": Consts.STATS_CHOICE_SCREEN,
                            "choiceTitle": payloadMessage["Title"], // Access fields from the parsed payload
                            "choiceOptions": payloadMessage["PlayerStatChoices"],
                        });
                        break;
                }

                break;
            default:
                this.setState({"currentWindow": Consts.WAITING_SCREEN})
        }
    }

    ConfirmPlayerStateReceived()
    {
        let uid = localStorage.getItem('uid');

        this.rws.send(JSON.stringify({
            messageType: Consts.CONFIRM_PLAYER_STATE_RECEIVED_BY_CLIENT,
            roomCode: this.state.roomCode,
            userUID: uid,
        }));
    }

    moveToWaitingCallback() {
        this.setState({"currentWindow": Consts.WAITING_SCREEN})
    }

    renderMainScreen()
    {
        switch (this.state["currentWindow"]) {
            case Consts.LOGIN_SCREEN:
                return (<LoginScreen socket={this.rws} callback={this.moveToWaitingCallback}/>);
            case Consts.WAITING_SCREEN:
                return (<LoadingScreen/>)
            case Consts.PLAYER_CHOICE_SCREEN:
                return (<SelectOptionScreen
                    options={this.state["choiceOptions"]}
                    title={this.state["choiceTitle"]}
                    userID={this.state["userID"]}
                    roomCode={this.state["roomCode"]}
                    callback={this.moveToWaitingCallback}
                    socket={this.rws}/>)
            case Consts.ROOM_FULL:
                return <RoomFull/>;
            case Consts.RECONNECTED_PLAYER:
                return <ReconnectedPlayer/>;
            case Consts.KNOCKED_OUT_SCREEN:
                return <KnockedOut/>;
            case Consts.DOUBLE_ANSWERS_SCREEN:
                return (
                    <DoubleAnswersScreen
                        options={this.state["choiceOptions"]}
                        title={this.state["choiceTitle"]}
                        userID={this.state["userID"]}
                        roomCode={this.state["roomCode"]}
                        callback={this.moveToWaitingCallback}
                        socket={this.rws}
                    />
                );
                break;
            case Consts.CORRECT_ANSWER_SCREEN:
                return (
                    <CorrectAnswerScreen
                        options={this.state.choiceOptions}
                        title={this.state.choiceTitle}
                        selectedOptions={this.state.selectedOptions}
                        correctIndex={this.state.correctIndex}
                        displaySleeves = {this.state.isBonus}
                    />
                );
                break;

            case Consts.TIMEOUT_SCREEN:
                return <TimedOut/>;
                break;

            case Consts.STATS_CHOICE_SCREEN:
                return (
                    <StatsChoiceScreen
                        options={this.state.choiceOptions}
                        title={this.state.choiceTitle}
                        userID={this.state["userID"]}
                        roomCode={this.state["roomCode"]}
                        callback={this.moveToWaitingCallback}
                        socket={this.rws}
                    />
                );
                break;

            default:
                return (<div/>)
        }
    }

    render() {
        let uid = localStorage.getItem('uid');
        return (<Container maxWidth={false}>
            <WakeLock/>

            <Paper>
                <link rel="stylesheet"
                      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>

                {this.renderMainScreen()}
            </Paper>
            <CurrentStateDebug
                currentScreen={this.state["currentWindow"]}
                socket={this.rws}
                roomCode={this.state.roomCode}
                userID={uid}
                lastMessageHash={this.state.lastMessageHash}/>
        </Container>)
    }
}

export default App;
